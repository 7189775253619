import { Http } from '@/apis/Http'

export default {
  fetchAll() {
    return Http.get('/admin/moderations')
  },

  fetchDetailsForUpdate(id) {
    return Http.get(`/admin/moderations/${id}`)
  },

  store(params) {
    return Http.post('/admin/moderations', params)
  },

  save(dealershipDetails, managers, moderationId) {
    return Http.post('/admin/moderations/save', {
      dealershipDetails,
      managers,
      moderationId
    })
  }
}
